import styled, { css } from 'styled-components';

export const Row = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.labelColor};
  font-size: 12px;

  &::after {
    ${(props) => props.isRequired && `content: '*';`}
    display: inline-block;
  }
`;

export const fieldStyle = css`
  border-radius: 0;
  border: ${(props) => props.theme.formBorder};
  width: 100%;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    ${(props) => props.theme.name === 'men' && `background: transparent;`}
    color: ${(props) => (props.theme.name === 'men' ? `#ffffff` : `#0000000`)};
  }
`;

export const StyledTextArea = styled.textarea`
  ${fieldStyle}
`;

export const StyledInput = styled.input`
  ${fieldStyle}
`;


export const StyledSelect = styled.select`
  ${fieldStyle}
  padding: 2px;
  display: none;
`;
