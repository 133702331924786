import axios from 'axios';

let baseURL = process.env.AXIOS_BASE_URL;

const instance = axios.create({
  baseURL: baseURL,
});

// instance.defaults.headers.common['Authorization'] =
//   'Basic dWF0OmRhdGF3b3Jkcw==';

export default instance;
